@import url("https://fonts.googleapis.com/css2?family=Anonymous+Pro:ital,wght@0,400;0,700;1,400;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Alegreya+Sans+SC:ital,wght@0,100;0,300;0,400;0,500;0,700;0,800;0,900;1,100;1,300;1,400;1,500;1,700;1,800;1,900&display=swap");

:root {
  --bs-dropdown-link-color: white !important;
  --theme-color: rgb(15, 15, 15);
}

html,
body {
  margin: 0;
  padding: 0;
}

@font-face {
  src: url("../fonts/Orpheus.otf");
  font-family: Orpheus
}

body {
  background-color: rgb(15, 15, 15) !important;
  /* font-family: "Alegreya Sans SC", sans-serif!important; */
  color: white;
  /* font-weight: 400; */
  height: 100vh;
  width: 100vw;
  /* overflow: hidden; */
  overflow-x: hidden;
}

input[type="text"] {
  color: white !important;
  background-color: #000 !important;
  border: 2px;

  border-style: double;
  border-color: rgb(88, 89, 89);
  border-radius: 5px;
}

.create-profile-div-manage {
  /* position: absolute; */
  /* margin-left: 52220px!important; */
}

.add-divisions {
  /* margin-left: 10%; */
  height: 100% !important;
  /*width: 200%!important; */
  /* --bs-card-height: 50%!important; */
  overflow-y: scroll;
  /* max-height: 0%; */
}

.profile-creator .card-body {
  flex: 0 1 auto !important;
}

.add-divisions .card-body {
  flex: 0 1 auto !important;
}

.scroll {
  overflow-y: scroll !important;
  max-height: 100%;
}

th {
  font-variant: small-caps;
}

td {
  font-variant: small-caps;
  color: rgb(255, 255, 255);
}

.table {
  --bs-table-hover-color: rgb(219, 255, 211) !important;
  --bs-table-color: white !important;
  --bs-table-striped-color: rgb(184, 184, 184) !important;
  user-select: none;
}

.create-profile-data {
  margin-right: 50px;
}

.internal-list-box {
  border-radius: 5px !important;
}

.form-select {
  background-color: var(--theme-color) !important;
  color: white !important;
}

.rank-selector {
  margin-left: 30%;
  /* margin-bottom: -10%; */
  margin-top: -11%;
  border: none !important;
  position: absolute;
  width: max-content !important;
}

.no-border {
  border: none !important;
}

.form-check-input {
  background-color: var(--theme-color) !important;
  border-color: white !important;
  margin-left: -10px !important;
}

.form-check-input:checked {
  background-color: rgb(247, 128, 1) !important;
  border-color: white !important;
}

.create-profile-arrow {
  margin-top: 50px;
  margin-left: -50px;
}

.add-div-arrow {
  margin-top: 50px;
  margin-left: -10px;
}

.div-list td:hover {
  color: rgb(247, 128, 1) !important;
}

.div-list {
  overflow-y: scroll !important;
  max-height: 100px;
}

::-webkit-scrollbar {
  scrollbar-gutter:auto;
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f100;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.find-user-results {
  overflow-y: scroll;
  max-height: 40vw;
  border: 2px !important;
  border-color: white !important;
  border-radius: 5px;
  width: 80% !important;
  display: grid;
  overflow-x: hidden;
  max-width: 80% !important;
  /* display: --webkit-box; */
  /* display: -webkit-inline-box!important; */
  position: absolute;
  grid-template-columns: repeat(5, 1fr);

  grid-auto-rows: auto;

  grid-gap: 1rem;
}

.find-user-results .card:hover {
  border-color: rgb(50, 89, 49) !important;
}

.create-profile {
  border: 2px !important;
  border-color: white !important;
  border-radius: 5px;
  display: inline-flex;
  width: 1000px;
}

.member-manage nav {
  /* border: 2px white!important; */
  /* --bs-navbar-toggler-border-color: white!important; */
  /* --bs-navbar-hover-color: white!important; */
  font-variant: small-caps;
}

.card {
  --bs-card-bg: rgb(15, 15, 15) !important;
  --bs-card-border-color: white !important;
  --bs-card-color: white !important;
  margin-bottom: 10px;
  /* padding: 1%; */
}

.hero-header {
  font-family: Orpheus;
  font-size: 100px;
  mix-blend-mode: difference;
  /* -webkit-text-stroke: 1px white;
  -webkit-text-fill-color: #000; */
  user-select: none;
  filter:blur(.5px) invert();
  
}

.list-group-item {
  --bs-list-group-bg: rgb(15, 15, 15) !important;
  --bs-list-group-color: white !important;
  --bs-list-group-border-color: white !important;
}
.mm-lg:hover {
  --bs-list-group-border-color: rgb(0, 110, 255) !important;
}

.bd-filt {
  transition: all 0.5s;
  backdrop-filter: blur(251px) invert();
  opacity: 0.5;
}

.grad-bg {
  transition: all 0.5s;
  /* background-image: url("https://images.unsplash.com/photo-1604076913837-52ab5629fba9?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxleHBsb3JlLWZlZWR8M3x8fGVufDB8fHx8fA%3D%3D&w=1000&q=80"); */
  background-color: #00000000;
  mix-blend-mode: color-dodge;
  background-position-y: 15%;
  background-attachment: fixed;
  background-repeat: no-repeat;

  background-size: cover;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  z-index: 1000;
  height: 10%;
  filter: invert();
}

.mm-bb {
  margin: 50% !important;
}

.mm-nb {
  background-color: #f1f1f1 !important;
  background: none;
  --bs-light-rgb: black !important;
}

.grad-bg:hover {
  filter: hue-rotate(40deg) !important;
  background-position-y: 90%;
  background-position-x: 35%;
}

.find-user-property {
  font-variant: small-caps;
  color: rgb(84, 173, 164) !important;
}

.find-user-property {
  font-variant: small-caps;
  color: rgb(224, 153, 77);
}
.find-user-property-1 {
  font-variant: small-caps;
  color: rgb(224, 192, 77);
}

.find-user-property-2 {
  font-variant: small-caps;
  color: rgb(163, 224, 77);
}

.find-user-property-3 {
  font-variant: small-caps;
  color: rgb(77, 224, 128);
}

.dropdown-item {
  /* color: white!important;
  background-color: #000!important; */
  font-variant: small-caps;
  font-weight: 300 !important;
}

.form-control {
  color: white !important;
  background-color: rgb(15, 15, 15) !important;
}

input[type="text"]::placeholder {
  color: white;
  opacity: 30%;
}

.dropdown-toggle {
  --bs-btn-border-color: white !important;
}

.btn-dark {
  --bs-btn-bg: rgb(15, 15, 15) !important;
  --bs-btn-hover-bg: white !important;
  --bs-btn-hover-color: black !important;
}

.btn-outline-secondary {
  --bs-btn-border-color: white !important;
  color: white;
  --bs-btn-color: white !important;
  --bs-btn-hover-bg: white !important;
  --bs-btn-hover-color: black !important;
}

button {
  font-variant: small-caps !important;
}

.add-profile {
  /* color: white; */
  display: inline-block !important;
  margin-left: 10%;
  width: 25%;

  /* background-color: rgb(59, 59, 59); */
}

.submit-button {
  border-color: rgb(7, 255, 7) !important;
}

.warning-modal .modal-content {
  border-color: orange !important;
}

.modal {
  --bs-modal-bg: var(--theme-color) !important;
  --bs-modal-color: white !important;
  --bs-modal-border-color: white !important;
}

.dropdown-menu {
  background-color: #333;
  --bs-dropdown-bg: rgb(15, 15, 15) !important;
  color: white !important;
  --bs-dropdown-link-color: white !important;
  --bs-dropdown-border-color: white !important;
}

input[type="radio"] {
  background: rgb(0, 0, 0);
  color: wheat;
  accent-color: rgb(52, 52, 52);
  border-bottom: 1px solid #333;
  filter: invert();
}

input[type="radio"]:hover {
  background: rgb(168, 54, 54);
  color: rgb(227, 149, 3);
  accent-color: rgb(87, 37, 37);

  mix-blend-mode: difference;
  filter: invert(), brightness(100), drop-shadow(35px 35px red);
  /* outline:1px solid #F00; */
}

.navbar-top,
.nav-container {
  position: absolute;
  display: inline-flex;
  padding-left: 3px;
  padding-right: 3px;
  text-align: center;
  margin-left: 150px;

  border: 1px rgb(233, 233, 233);

  /* border-radius: 5px;
  border-style:groove; */
  float: left;
  text-decoration: none;
  list-style-type: none;
}

li {
  text-decoration: none;
  display: block;
  min-height: 10px;
}

label {
  user-select: none;
  color: white;
}

.nav-button,
.nav-data,
.button {
  height: 100%;
  display: inline-table;
  float: left;
  position: inherit;
  height: inherit;
  text-align: left;
  list-style: none;

  margin-right: 5px;
  margin-left: 5px;
  margin-top: 2px;
  margin-bottom: 2px;

  font-size: large;

  padding-left: 5px;
  padding-right: 5px;

  text-decoration: none;
  user-select: none;
  transition: all 0.5s;
  background-color: rgb(15, 15, 15);
  color: white;
  font-variant: small-caps;

  border: 1px;
  border-radius: 5px;
  border-style: groove;
}

.btn-primary {
  background-color: rgb(15, 15, 15) !important;
  color: white;
  border-color: white !important;
}

.btn-primary:hover {
  background-color: white !important;
  color: var(--theme-color) !important;
}

.member-manage {
  /* background-color: #f1f1f1 !important; */
  padding-left: 5% !important;
  padding-right: 5% !important;
  max-width: 1520px !important;
  background-size: cover !important;
  height: 100%;
}
.logs {
  background-color: #f1f1f100 !important;
  padding-left: 5% !important;
  padding-right: 5% !important;
  max-width: 1520px !important;
  font-family: "Roboto Mono", monospace;
  font-weight: 400 !important;
  backdrop-filter: blur(5px);
}

.log-data {
  --bs-table-striped-color: rgb(56, 56, 56) !important;
  --bs-table-color: rgb(56, 56, 56) !important;
  color: rgb(56, 56, 56);
  font-weight: 400;
  filter: blur(0.2px);
  --bs-table-hover-bg: rgb(68, 68, 68) !important;
}
.log-button {
  cursor: alias;
}

.log-data:hover {
  color: rgb(219, 255, 211) !important;
}

.button-delete {

}
.button-delete:hover{
  color: rgb(255, 0, 0) !important;
}

.button-delete:active{
  filter:hue-rotate(80deg) blur(1px)
}

tr:hover {
  color: rgb(219, 255, 211);
}

.diff-viewer {
  font-family: "Anonymous Pro", monospace;
  --bs-offcanvas-bg: rgb(15, 15, 15) !important;
}

.created {
  color: greenyellow !important;
}

.deleted {
  color: rgb(255, 0, 0) !important;
}

.unchanged {
  color: rgb(255, 255, 255) !important;
}

.updated {
  color: rgb(83, 77, 255) !important;
}

.nav-data {
  border-color: goldenrod;
  /* color: green */
}
.listItem {
  width: 10% !important;
}

.query-mem-name {
  width: 17% !important;
  font-variant: small-caps;
}

.raro-data {
  width: 13% !important;
  font-variant: small-caps;
  color: rgb(77, 224, 128) !important;
}

.raro-data-div-list {
  text-align: left !important;
}

.manage-members {
  /* background-color: #f1f1f1 !important; */
}

.view-roles {
  color: rgb(60, 60, 60);
  user-select: none;
}

.view-roles:hover {
  transition: all 0.5s;
  color: rgb(255, 149, 0);
}

.nav-button:hover,
.button:hover {
  background-color: rgb(202, 202, 202);
  color: black;
}

.nav-button:active,
.button:active {
  transition: all 0.05s;
  /* box-shadow: 2px 2px 15px rgba(255, 255, 255, 0.6),-2px -2px 15px rgba(255, 255, 255, 0.6); */
  text-shadow: 0px 0px 1px black;
  background-color: white;
}

.table-variant-1 {
  --bs-table-color: grey !important;
  --bs-table-striped-color: rgb(134, 134, 134) !important;
  color: black;
  --bs-table-bg: #d7d7d7 !important;
}

.sprite {
  background-image: none;
  background-repeat: no-repeat;
  display: block !important;
}

.sprite:active {
  filter: invert();
}

.noround {
  border-radius: 0px !important;
}

.nav-button {
  mix-blend-mode: difference;
  background: none;
}

.component-accordion {
  filter: invert();
}

.add-option-table {
  border-radius: 5px !important;
}

.Button-1 {
  background-color: rgba(0, 0, 0, 0.075) !important;
}

.Button-1:hover {
  color: rgb(219, 255, 211) !important;
}

.finished-proto {
  background-color: var(--theme-color);
  padding: 5px;
  background-size: cover;
}

.new-app {
  height: 600px;
  overflow-y: hidden !important;
}

.add-components {
  height: 100%;
  overflow-y: scroll !important;
  padding: 2px;
  border-radius: 10px;
  overflow-x: hidden !important;
  margin-left: 2% !important;
}

.app-header {
  font-variant: small-caps;
  text-align: center;
}

.btn.backblur {
  border: none !important;
  background-color: #e8e8e800 !important;
  backdrop-filter: blur(85px) saturate(200%);
  mix-blend-mode: difference;
  /* border-radius: 10px;
  margin-top: 5px;
  margin-bottom: 10%;
  padding-bottom: 5%;
  ; */
}

.review-app {
  --bs-offcanvas-bg: rgba(15, 15, 15, 0)!important;
  --bs-offcanvas-color:white!important;
}

.square {
  border-radius: 0px !important;
}

.accept {
  color:rgb(125, 238, 155);

}
.accept:hover {
  color:rgb(3, 255, 70);
}

.deny {
  color: rgb(239, 137, 137) !important;
}

.deny:hover {
  color: rgb(246, 78, 78) !important;
}

.ai {
  color:rgb(118, 169, 235)
}

.ai:hover {
  color:rgb(40, 126, 240)
}

.review {
  color: rgb(131, 111, 142)
}

.review:hover {
  color: rgb(194, 115, 236)
}



.pretty-btn {
  -webkit-text-stroke: .7px black;
    filter:invert()
  /* border-radius: 10px;
  margin-top: 5px;
  margin-bottom: 10%;
  padding-bottom: 5%;
  ; */
}

.auth-rbx-btn:hover {
  filter: invert();
}

.white {
  color: white!important;
}

.smallcaps {
  font-variant: small-caps;
}

.hue-rot {
color:#333
}

.small {
  font-size: 10px!important;
}

.mono {
  font-family: "Roboto Mono", monospace;
}

.app-builder {
  mix-blend-mode: luminosity;
  backdrop-filter: blur(85px) saturate(200%);
  background-color: #e8e8e800;
  border-radius: 10px;
  margin-top: 5px;
  margin-bottom: 10%;
  padding-bottom: 5%;
  --bs-card-border-width: 0px!important;
}

.backblur-1 {
  backdrop-filter: blur(85px) saturate(120%) ;
  background-color: #e8e8e800;
  box-shadow: 0px 0px 5px 5px #1b1b1b1d;
}

.application {
  background-color: #f1f1f121 !important;
  border-radius: 10px;
  margin-top: 5px;
  margin-bottom: 10%;
  padding-bottom: 5%;
  padding:5%;
}

.backblur {
  mix-blend-mode: luminosity;
  backdrop-filter: blur(85px) saturate(120%) ;
  background-color: #e8e8e800;
  box-shadow: 0px 0px 5px 5px #1b1b1b1d;
}

.back-blur {
  -webkit-backdrop-filter: blur(15px) saturate(50%);
  background-size: cover !important;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.canvas-grad {
  position: absolute;
  /* background-color: #f1f1f1!important; */
  background-image: url(background.jpg);
  background-size: cover !important;
  width: 100%;
  height: 100%;
  filter:blur(505px) invert();
  z-index: -100;
}

.flowing-gradient {
  position: absolute;
  /* background-color: #f1f1f1!important; */
  
  background-size: cover !important;
  width: 100%;
  height: 100%;

  z-index: -100;
}

.mbm-dif {
  color: white!important;
  mix-blend-mode: difference!important;
  user-select: none;
}



.white-bg {
  position: fixed;
  /* background-color: #f1f1f1!important; */
  background-image: url(background.jpg);
  background-size: cover !important;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.query-image {
  width: 30%;
  height: 30%;
  border-radius: 50%;
  border: 1px solid white;
  margin-right: 5px;
  margin-left: 5px;
}

.data-cl-im {
  width: 13% !important;
  font-variant: small-caps;
}

.data-spec {
  font-variant: small-caps;
  color: rgb(77, 197, 224) !important;
}

.data-spec-1 {
  font-variant: small-caps;
  color: rgb(164, 17, 93) !important;
}

.data-modify {
  width: 15% !important;
  font-size: small !important;
}

.modify-profile {
  /* width: 20%!important; */
  font-size: small !important;
}

.spec-disp {
  filter: blur(0.4px);
  border: none !important;
  mix-blend-mode: difference!important;
}

.black {
  color:black!important;
}

.spec-disp-1 {
  filter: blur(0.4px);

  /* background-blend-mode: difference; */
  /* mix-blend-mode: difference; */
  -webkit-text-stroke: 0.05px #d7d7d7;
}

.spec-disp:hover {
}

.sortbar {
  background-color: rgba(0, 0, 0, 0.075) !important;
}

.filter-search {
  max-width: 20% !important;
  max-height: 50% !important;
  margin-left: 5px !important;

  font-size: small;
  color: #e9e9e9 !important;
}
.btn-close {
  backdrop-filter: difference !important;
  filter: invert(1) !important;
}

.filter-search::placeholder {
  font-variant: small-caps;
  font-size: small;
}

.noborder {
  border: none !important;
}


#tiles {
  height: calc(100vh - 1px);
  width: calc(100vw - 1px);
  position: relative;
  z-index: 2;
  
  display: grid;
  grid-template-columns: repeat(var(--columns), 1fr);
  grid-template-rows: repeat(var(--rows), 1fr);
}

